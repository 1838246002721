import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentWrapper = ({ wording }) => {
  return (
    <CookieConsent
      style={{
        fontWeight: '500',
        backgroundColor: 'rgba(43,48,54,0.95)',
        borderRadius: '0px',
      }}
      containerClasses="notification"
      buttonClasses="button is-primary"
      buttonStyle={{
        borderRadius: '4px',
        fontWeight: '400',
        background: '#276cda',
        color: '#fff',
      }}
    >
      {wording}{' '}
    </CookieConsent>
  );
};

// TODO
// Add graphql to component for website
CookieConsentWrapper.defaultProps = {
  wording: 'This website uses cookies to enhance the user experience',
};

export default CookieConsentWrapper;
