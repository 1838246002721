import React from 'react';
// import ReactDOM from 'react-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import SEO from '../components/SEO';
import './all.sass';
import useSiteMetadata from './SiteMetadata';
// import { withPrefix } from 'gatsby';
import CookieConsentWrapper from './CookieConsentWrapper';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div className="layout">
      <SEO title={title} description={description} />
      <Navbar />
      <div>{children}</div>
      <Footer />
      <CookieConsentWrapper />
    </div>
  );
};

export default TemplateWrapper;
